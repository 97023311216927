import React from 'react';
import { CircleProgress } from 'react-gradient-progress';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import { color } from 'common/styledVars';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  BaseTransparentP,
  BaseP,
  HeaderMedium,
  MediumText,
  MediumTextTransparent,
} from 'components/typography';
import IteratingCards from 'components/cards/IteratingCards';
import { Property } from 'components/pages/CaseStudies/styled';
import { ZulilyLogo } from 'components/icons';
import { WidthWrapper, RelativeWrapper } from 'components/wrappers';
import { Arrow, ValueUnit } from 'components/pages/Home/Partners/styled';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import {
  ImageBox,
  HeaderSection,
  ResultCard,
  CardsContainer,
  CircleBox,
  TopTextWrapper,
  TextSmallWrapper,
  ImageWrapper,
  GatsbyImage,
  ContentWrapper,
  AreasWrapper,
} from 'components/pages/CaseStudies/styled';
import BoxIcon from 'assets/box.svg';
import StockIcon from 'assets/stock-2.svg';
import StorageUnit from 'assets/storage-unit.svg';

const CaseStudiesPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query ImgZulilyPictureQuery {
      file(relativePath: { eq: "zulily-case.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

const MainTitle = styled.h1`
    font-size: 28px;
    font-weight: 500;
    margin: 16px 0px 32px;

  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
      margin: 42px 0 80px;
  }
`;

const OutLink = styled.a`
    color:#9db1ad;
    z-index:9999;

    &:hover {
      color:#00af85
    }
`;

  return (
    <Layout>
      <Helmet>
        {/* <title>Know More About Zulily | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-zilly" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "zulily-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "zulily-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "zulily-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "zulily-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Get to know why Zulily have decided to pick Shipkoo to handle their most crucial logistics operations." />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-zilly" })} />
        <meta property="og:description" content="Get to know why Zulily have decided to pick Shipkoo to handle their most crucial logistics operations." />
        <meta property="og:image" content="" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-zilly" })} />
        <meta name="twitter:description" content="Get to know why Zulily have decided to pick Shipkoo to handle their most crucial logistics operations." />
      </Helmet>
      <RelativeWrapper>
        <WidthWrapper>
          <HeaderSection>
            <BaseP>{intl.formatMessage({ id: "zulily-subheader" })}</BaseP>
            <MainTitle>
              {intl.formatMessage({ id: "zulily-header" })}
            </MainTitle>
            <BaseTransparentP>
              {intl.formatMessage({ id: "zulily-description" })}
            </BaseTransparentP>
          </HeaderSection>
        </WidthWrapper>
        <OutLink href="https://www.zulily.com/" target="_blank">
          <ImageBox>
            <ZulilyLogo alt="Zulily Logo" />
          </ImageBox>
        </OutLink>
      </RelativeWrapper>

      <WidthWrapper>
        <CardsContainer>
          <ResultCard>
            <CircleBox color={color.green.aquaSqueeze}>
              <CircleProgress
                percentage={50}
                width={159}
                secondaryColor="transparent"
                primaryColor={['#8bdfbd', '#00b399']}
              />
              <Property>
                50
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Property>
            </CircleBox>
            <MediumText>{intl.formatMessage({ id: "zulily-warehousing" })}</MediumText>
            <BaseTransparentP>{intl.formatMessage({ id: "zulily-costs" })}</BaseTransparentP>
          </ResultCard>
          <ResultCard>
            <CircleBox color="#F8F3FF">
              <CircleProgress
                percentage={40}
                width={159}
                secondaryColor="transparent"
                primaryColor={['#d7bfff', '#ae8bff']}
              />
              <Property>
                40
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Property>
            </CircleBox>
            <MediumText>{intl.formatMessage({ id: "zulily-shipping" })}</MediumText>
            <BaseTransparentP>{intl.formatMessage({ id: "zulily-costs" })}</BaseTransparentP>
          </ResultCard>

          <ResultCard>
            <CircleBox color="#EEFBFF">
              <CircleProgress
                percentage={20}
                width={159}
                secondaryColor="transparent"
                primaryColor={['#59daff', '#00c6ff']}
              />
              <Property>
                20
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Property>
            </CircleBox>
            <MediumText>{intl.formatMessage({ id: "zulily-operating" })}</MediumText>
            <BaseTransparentP>{intl.formatMessage({ id: "zulily-costs" })}</BaseTransparentP>
          </ResultCard>
        </CardsContainer>
      </WidthWrapper>

      <TopTextWrapper>
        <HeaderMedium>{intl.formatMessage({ id: "zulily-who-title" })}</HeaderMedium>
        <MediumTextTransparent>
          {intl.formatMessage({ id: "zulily-who-description" })}
        </MediumTextTransparent>
      </TopTextWrapper>
      <ImageWrapper>
        <GatsbyImage fluid={data.file.childImageSharp.fluid} alt="Zuliliy banner" />
      </ImageWrapper>

      <WidthWrapper size={640}>
        <ContentWrapper>
          <TextSmallWrapper style={{ marginLeft: 'auto' }}>
            <BaseP>{intl.formatMessage({ id: "zulily-challenge-title" })}</BaseP>
            <BaseTransparentP>
              {intl.formatMessage({ id: "zulily-challenge-description" })}
            </BaseTransparentP>
          </TextSmallWrapper>
          <TextSmallWrapper>
            <BaseP>{intl.formatMessage({ id: "zulily-solution-title" })}</BaseP>
            <BaseTransparentP>
              {intl.formatMessage({ id: "zulily-solution-description" })}
            </BaseTransparentP>
          </TextSmallWrapper>
          <TextSmallWrapper>
            <BaseP>{intl.formatMessage({ id: "zulily-improvement-title" })}</BaseP>
            <BaseTransparentP>
              {intl.formatMessage({ id: "zulily-improvement-description" })}
            </BaseTransparentP>
          </TextSmallWrapper>
        </ContentWrapper>
      </WidthWrapper>

      <AreasWrapper>
        <IteratingCards
          cards={[
            {
              title: 'Pick & Pack',
              text: 'Lower labor fulfillment costs in China',
              Icon: BoxIcon,
              titleZH: '提货打包',
              textZH: '中国的劳动力及货物操作成本较低',
              titleKO: '픽앤팩',
              textKO: '중국의 노동 이행 비용 절감',
              titleJP: 'ピックアンドパック',
              textJP: '中国における労働履行コストの削減',
            },
            {
              title: 'Warehousing',
              text: 'Less need to engage much warehouse space in the US during peak season because goods aren’t held up at sea',
              Icon: StockIcon,
              titleZH: '仓配中心',
              textZH: '在高峰季节，由于不需要在海上滞留货物，因此在美国占用大量仓库空间的需求更少',
              titleKO: '입고',
              textKO: '성수기 동안 미국 내 창고 공간을 많이 차지해야 하는 필요성 감소',
              titleJP: '倉庫',
              textJP: '商品が海上で停滞しないため、ピークシーズンに米国の多くの倉庫スペースを利用する必要性が少なくなります。',
            },
            {
              title: 'Inventory Level',
              text:'Greater ability to manage a larger number of SKUs because Zulily doesn’t have to keep so much inventory',
              Icon: StorageUnit,
              titleZH: '库存',
              textZH: '由于Zulily不必保留太多库存，因此具有更大的能力来管理大量SKU',
              titleKO: '재고 수준',
              textKO: 'Zulily가 그렇게 많은 재고를 보유할 필요가 없기 때문에 더 많은 수의 SKU를 관리할 수 있는 더 큰 능력',
              titleJP: '在庫レベル',
              textJP: 'Zulilyはそれほど多くの在庫を保持する必要がないため、より多くのSKUを管理できる優れた機能',
            },
          ]}
        />
      </AreasWrapper>
      <WidthWrapper size={640}>
        <ContentWrapper>
          <BaseTransparentP>
            <BaseTransparentP>{intl.formatMessage({ id: "zulily-bottom-description" })}</BaseTransparentP>
          </BaseTransparentP>
        </ContentWrapper>
      </WidthWrapper>
    </Layout>
  );
};

export default CaseStudiesPage;
